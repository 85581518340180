<template>
  <teleport v-if="isMounted" :to="`#form-title-${props.for}`">
    <div class="card-toolbar m-0 ms-2">
      <ul class="nav nav-tabs nav-line-tabs nav-stretch">
        <li v-for="(tab, i) in $_CONFIG" :key="`form-tab-${i}`" class="nav-item">
          <a :class="['nav-link text-active-primary', i === 0 ? 'active' : '']" data-bs-toggle="tab" :href="`#${tab.key}`">
              <span v-if="tab.icon" class="nav-icon">
                <i :class="tab.icon" />
              </span>
            <span class="nav-text">{{ tab.title }}</span>
          </a>
        </li>
      </ul>
    </div>
  </teleport>
  <div class="tab-content">
    <div v-for="(tab, i) in $_CONFIG" :id="tab.key" :key="`form-tab-content-${i}`" class="tab-pane fade" :class="i === 0 ? 'active show' : ''" role="tabpanel" :aria-labelledby="`tab-${i}`">
      <slot :name="tab.key" :data="$_VALUE[tab.key]" :config="config">
        <component
          v-for="(config, k) in tab.config" :key="`form-tab-component-${i}-${k}`"
          :ref="el => components[tab.key] = el"
          :is="tab.component"
          :full-data="fullData"
          :for="tab.key"
          v-model="$_VALUE[tab.key]"
          :incremental-mode="incrementalMode"
          :config="config"
        />
      </slot>
    </div>
  </div>
</template>

<script setup>
import { defineExpose, defineProps, defineEmits, onBeforeMount, onMounted, ref, watch } from 'vue'
import { getComponent } from './internal-types'
import { display, isEmpty, isEqual, typeOf } from '../utils/common'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  config: {},
  for: { type: String },
  title: { type: String },
  incrementalMode: { type: Boolean, default: false },
  // 全量表单数据
  fullData: { type: Object, default: () => {} },
  modelValue: { type: Object, default: () => { return {} } }
})

const $_VALUE = ref({})
const $_KEY_LIST = ref({})
const $_CONFIG = ref([])
const components = ref({})
const isMounted = ref(false)

watch(() => props.modelValue, value => {
  if (!isEmpty(value) && !isEqual(value, $_VALUE.value)) {
    $_VALUE.value = value
  }
})

watch($_VALUE, value => {
  if (!isEmpty(value)) {
    emit('update:modelValue', value)
  }
}, { deep: true })

function init () {
  const config = { ...props.config }
  const tabsConfigs = []
  const value = {}
  const keyList = {}

  for (const i in config.tabs) {
    const tabConfig = { ...config.tabs[i] }
    value[tabConfig.key] = {}
    tabConfig.componentRaw = tabConfig.component ?? tabConfig.type
    tabConfig.component = getComponent(tabConfig.componentRaw)
    tabConfig.config = tabConfig.config.configs ?? tabConfig.config
    keyList[tabConfig.key] = {
      display: display(tabConfig.display),
      required: tabConfig.required,
      validation: tabConfig.validation ? parseValidationConfig(tabConfig.validation) : undefined
    }
    tabsConfigs.push(tabConfig)
  }

  console.log('tabsConfigs', tabsConfigs)

  $_CONFIG.value = tabsConfigs
  $_VALUE.value = value
  $_KEY_LIST.value = keyList
}

function parseValidationConfig (config) {
  return (typeOf(config) !== 'array') ? [config] : config
}

function validation () {
  const validationResult = {
    status: 0,
    message: '',
    data: {}
  }
  const validationList = {}
  for (const k in $_KEY_LIST.value) {
    // 判断是否display数据
    if ($_KEY_LIST.value[k].display($_VALUE.value)) {
      // 判断子组件是否有verify方法
      if (components.value[k].verify) {
        validationList[k] = components.value[k].validate()
        if (validationList[k].status !== 0) {
          validationResult.status = 1
        }
      } else {
        if ($_KEY_LIST.value[k].required && [null, undefined, ''].includes($_VALUE.value[k])) {
          validationList[k] = {
            status: 1,
            message: '此项必填'
          }
          validationResult.status = 1
        } else {
          validationList[k] = {
            status: 0,
            message: ''
          }
        }
      }
    }
  }
  validationResult.data = validationList
  return validationResult
}

onBeforeMount(() => {
  init()
})

onMounted(() => {
  isMounted.value = true
})

defineExpose({ validation })
</script>
