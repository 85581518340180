import { defineStore } from 'pinia'
import { initCondition } from '@/utils/condition'
import SystemConfigService from '@/services/system/config'

export const useConfigStore = defineStore({
  id: 'config',
  state: () => ({
    data: {},
    initialized: initCondition('config')
  }),
  getters: {
    list () {
      // 获取配置列表
      return this.data
    },
    isInitialized () {
      // 是否已经初始化
      return this.initialized.status
    }
  },
  actions: {
    async init () {
      // 初始化配置
      const result = (await SystemConfigService.getList({ page: 1, size: 1 })).records
      if (result.length > 0) {
        this.data = result[0]
      } else {
        this.data = {}
      }
      this.initialized.set()
    },
    has (key) {
      // 是否存在某个配置
      return this.data.includes(key)
    }
  }
})
